import React from "react";
import {makeStyles} from "@mui/styles";
import {Grid, Typography} from "@mui/material";

import InterregLogo from "../assets/images/logo_R4D.gif";
import KulturaMariborLogo from "../assets/images/Group 1763.svg";
import StudoLogo from "../assets/images/Studo_Logo-University-SI-4c-RGB-(Digital).png";
import UmLogo from "../assets/images/logotip-UM-hq.png";
import localizedStrings from "../assets/strings";
import InfoDropdown from "../components/dropdowns/infoDropdown";
import theme from "../theme";
import InfoSocialsButton from "../components/buttons/infoSocialsButton";

const Info = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.outerInfoContainer}>
      <Grid container className={classes.infoContainer}>
        <img src={InterregLogo} alt={"interreg"} className={`${classes.headerLogo} ${classes.sectionMargin}`} />
        <InfoDropdown title={localizedStrings.participatoryBudgeting}>
          <Typography
              className={classes.linkTextBlock}
              onClick={() => window.location.href = "https://www.mariborsodeluj.si/"}
          >
            {localizedStrings.activelyContribute}
          </Typography>
        </InfoDropdown>
        <Typography className={classes.sectionMargin}>
          {localizedStrings.infoTextStart}
        </Typography>
        <InfoDropdown title={localizedStrings.aboutProject}>
          <Typography>
            {localizedStrings.aboutProjectInfo}
          </Typography>
          <Typography className={classes.inSectionMargin}>
            {localizedStrings.aboutProjectIndentsTitle}
          </Typography>
          {[1, 2, 3].map((index) => (
            <Typography key={index}>
              {/*@ts-ignore*/}
              {localizedStrings[`aboutProjectIndent${index}`]}
            </Typography>
          ))}
        </InfoDropdown>
        <InfoDropdown title={localizedStrings.partners}>
          <Typography>
            {localizedStrings.infoPartners}
          </Typography>
        </InfoDropdown>
        <InfoDropdown title={localizedStrings.duration}>
          <Typography>
            {localizedStrings.infoDuration}
          </Typography>
        </InfoDropdown>
        <InfoDropdown title={localizedStrings.socials}>
          <Grid container spacing={2} direction={"column"}>
            <InfoSocialsButton
              title={localizedStrings.socialsFacebook}
              onClick={() => window.location.href = "https://fb.me/Restart4Danube"}
            />
            <InfoSocialsButton
              title={localizedStrings.socialsLinkedin}
              onClick={() => window.location.href = "https://www.linkedin.com/company/restart4danube"}
            />
            <InfoSocialsButton
              title={localizedStrings.socialsTwitter}
              onClick={() => window.location.href = "https://twitter.com/Restart4Danube"}
            />
            <InfoSocialsButton
              title={localizedStrings.socialsInstagram}
              onClick={() => window.location.href = "https://www.instagram.com/restart4danube/"}
            />
            <InfoSocialsButton
              title={localizedStrings.webpage}
              onClick={() => window.location.href = "http://www.interreg-danube.eu/approved-projects/restart-4danube"}
            />
          </Grid>
        </InfoDropdown>
        <Grid container className={classes.imageContainerFull}>
          <Grid item className={classes.imageContainer}>
            <img src={KulturaMariborLogo} className={classes.headerLogo} alt={"Mestna obcina Maribor"} />
          </Grid>
          <Grid item className={classes.imageContainer}>
            <img src={UmLogo} className={classes.headerLogo} alt={"Univerza v Mariboru"} />
          </Grid>
          <Grid item>
            <Typography>{localizedStrings.projectFinanced}</Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.dashContainer}>
          <div className={classes.titleDash} />
          <Typography className={classes.titleText}>
            {localizedStrings.creators}
          </Typography>
        </Grid>
        <Grid item className={classes.sectionMargin}>
          <img src={StudoLogo} className={classes.headerLogo} alt={"Studo"} />
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  outerInfoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#E6E6E6'
  },
  infoContainer: {
    padding: '30px',
    paddingBottom: '30px',
    textAlign: 'justify',
    maxWidth: '540px',
    backgroundColor: 'white'
  },
  headerLogo: {
    width: '100%',
  },
  sectionMargin: {
    marginBottom: '30px !important',
  },
  inSectionMargin: {
    marginTop: '10px !important'
  },
  linkText: {
    display: 'inline-block',
    textDecoration: 'underline',
    marginLeft: '20px !important',
  },
  inline: {
    display: 'inline'
  },
  imageContainer: {
    width: '43%',
    marginBottom: '20px !important',
    alignSelf: 'center'
  },
  imageContainerFull: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  dashContainer: {
    position: 'relative',
    marginTop: '100px !important',
    marginBottom: '20px !important'
  },
  titleDash: {
    display: 'inline-block',
    height: '3px',
    width: '18%',
    background: theme.palette.primary.main,
    position: 'absolute',
    top: '45%'
  },
  titleText: {
    paddingLeft: '40px !important',
    textTransform: 'uppercase',
    // @ts-ignore
    fontSize: `${theme.text.fontSize.medium3} !important`,
    // @ts-ignore
    fontWeight: `${theme.text.fontWeight.bold} !important`,
    color: theme.palette.primary.main,
  },
  linkTextBlock: {
    textDecoration: 'underline'
  }
})

export default Info;
