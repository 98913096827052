import KulturaMariborLogo from "../../assets/images/kultura-maribor-logo.svg";
import MladiMariborLogo from "../../assets/images/mladi-maribor-logo.png";
import VisitMariborLogo from "../../assets/images/visit-maribor-logo.svg";
import UmLogo from "../../assets/images/um-logo.png";
import SectionButtonClass from "../classes/SectionButton";

export const moreEventsData = [
  new SectionButtonClass("https://kultura.maribor.si/napovednik/",  "kultura.maribor.si", KulturaMariborLogo),
  new SectionButtonClass("https://mladimaribor.si/dogodki/",  "mladimaribor.si", MladiMariborLogo),
  new SectionButtonClass("https://www.visitmaribor.si/",  "visitmaribor.si", VisitMariborLogo),
]

export const partnersData = [
  new SectionButtonClass("https://maribor.si/",  "Mestna občina Maribor", KulturaMariborLogo),
  new SectionButtonClass("https://www.um.si/",  "Univerza v Mariboru", UmLogo),
]
