import React from "react";
import {Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import theme from "../../theme";
import localizedStrings from "../../assets/strings";
import useWindowDimensions from "../../utils/helpers/windowDimensions";

const Footer = () => {
  const classes = useStyles();
  const {width} = useWindowDimensions();

  return (
    <Grid
      container
      className={width > 540 ? `${classes.footerContainer} ${classes.footerHeight}` : classes.footerContainer}
    >
      <Grid item>
        <Typography className={classes.footerText} onClick={() => window.location.href = "https://studo.com/si/"}>
          {localizedStrings.madeByStudo}
        </Typography>
        {
          width > 540 && (
            <Typography className={classes.optimizedText}>
              {localizedStrings.optimizedForMobile}
            </Typography>
          )
        }
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  footerContainer: {
    height: '100px',
    backgroundColor: theme.palette.primary.main,
    justifyContent: 'center',
    padding: '5px 30px',
  },
  footerHeight: {
    height: '118px'
  },
  footerText: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'underline',
    // @ts-ignore
    fontSize: `${theme.text.fontSize.medium2} !important`,
    textAlign: 'center'
  },
  optimizedText: {
    color: theme.palette.primary.contrastText,
    // @ts-ignore
    fontSize: `${theme.text.fontSize.small} !important`,
  },
})

export default Footer;
