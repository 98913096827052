import React from "react";
import {Skeleton, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

type CustomTypographyProps = {
  className?: string,
  sx?: object,
  loading: boolean,
  loadingWidth: string | number,
  loadingHeight: string | number,
  type: "text" | "rectangular",
  children: any,
}

const CustomTypography = (props: CustomTypographyProps) => {
  const classes = useStyles();
  const {className, sx, loading, loadingWidth, loadingHeight, type, children} = props;

  return (
    <>
      {loading ? (
        <div className={classes.skeletonContainer}>
          <Skeleton className={classes.skeleton} variant={type} width={loadingWidth} height={loadingHeight} />
        </div>
      ) : (
        <Typography className={className} sx={sx}>{children}</Typography>
      )}
    </>
  );
}

const useStyles = makeStyles({
  skeletonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  skeleton: {
    borderRadius: '8px',
  }
})

export default CustomTypography;
