export default class SectionButtonClass {
    url: string;
    title: string;
    logo: string;

    constructor(url: string, title: string, logo: string) {
        this.url = url;
        this.title = title;
        this.logo = logo;
    }
}
