import React from "react";
import {Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import theme from "../../theme";
import SectionButtonClass from "../../utils/classes/SectionButton";

type ButtonSectionProps = {
  title: string,
  buttonList: SectionButtonClass[]
}

const ButtonSection = (props: ButtonSectionProps) => {
  const classes = useStyles();
  const {title, buttonList} = props;

  return (
    <Grid container className={classes.buttonsContainer} direction={"column"}>
      <Grid item className={classes.titleContainer}>
        <div className={classes.titleDash} />
        <Typography className={classes.titleText}>
          {title}
        </Typography>
      </Grid>
      <Grid container justifyContent={"space-between"}>
        {
          buttonList.map((button, index) => (
            <Grid container key={index} className={classes.button} onClick={() => window.location.href = button.url}>
              <img src={button.logo} className={classes.buttonImg} />
              <Typography className={classes.buttonTitle}>
                {button.title}
              </Typography>
            </Grid>
          ))
        }
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  buttonsContainer: {
    marginTop: '30px',
  },
  titleContainer: {
    position: 'relative'
  },
  titleDash: {
    display: 'inline-block',
    height: '3px',
    width: '7%',
    background: theme.palette.primary.main,
    position: 'absolute',
    top: '45%'
  },
  titleText: {
    paddingLeft: '40px !important',
    textTransform: 'uppercase',
    // @ts-ignore
    fontSize: `${theme.text.fontSize.medium3} !important`,
    // @ts-ignore
    fontWeight: `${theme.text.fontWeight.bold} !important`,
    color: theme.palette.primary.main,
  },
  button: {
    marginTop: '30px !important',
    width: '45%! important',
    padding: '10px !important',
    height: '120px',
    // @ts-ignore
    border: `2px solid ${theme.palette.buttonBorder.main}`,
    borderRadius: '10px',
    display: 'flex',
    // @ts-ignore
    flexDirection: 'column !important',
    justifyContent: 'space-between'
  },
  buttonTitle: {
    // @ts-ignore
    fontSize: `${theme.text.fontSize.medium2} !important`,
    // @ts-ignore
    fontWeight: `${theme.text.fontWeight.semiBold} !important`
  },
  buttonImg: {
    alignSelf: 'flex-start',
    objectFit: 'contain',
    height: '30%'
  }
})

export default ButtonSection;
