import React from "react";
import {Button, Skeleton, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import localizedStrings from "../../assets/strings";
import theme from "../../theme";

type ReadMoreButtonProps = {
  title: string,
  loading: boolean,
  onClick: () => void
}

const ReadMoreButton = (props: ReadMoreButtonProps) => {
  const classes = useStyles();
  const {title, loading, onClick} = props;

  return (
      <>
        {loading ? (
          <Skeleton className={classes.skeleton} variant={"rectangular"} width={"80%"} height={55.5} />
        ) : (
          <Button
            variant={"contained"}
            onClick={onClick}
            className={classes.buttonContainer}
            endIcon={
              <ArrowForwardRoundedIcon />
            }
          >
            <div className={classes.button}>
              <Typography>
                {localizedStrings.readMore}
              </Typography>
              <Typography className={classes.linkText}>
                {title}
              </Typography>
            </div>
          </Button>
        )}
    </>
  );
}

const useStyles = makeStyles({
  skeleton: {
    borderRadius: '20px'
  },
  buttonContainer: {
    width: '80%',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '20px !important'
  },
  button: {
    display: 'block !important',
    width: '80% !important',
    textAlign: 'left'
  },
  linkText: {
    // @ts-ignore
    fontSize: `${theme.text.fontSize.medium2} !important`,
    textTransform: 'lowercase'
  }
})

export default ReadMoreButton;
