import React, {useState} from "react";
import {makeStyles} from "@mui/styles";
import {Grid, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import theme from "../../theme";

type InfoDropdownProps = {
  title: string,
  children: any
}

const InfoDropdown = (props: InfoDropdownProps) => {
  const classes = useStyles();
  const { title, children } = props;

  const [showChildren, setShowChildren] = useState<boolean>(false);

  return (
    <Grid container className={classes.sectionContainer}>
      <Grid
        container
        className={classes.dropdownContainer}
        onClick={() => setShowChildren(!showChildren)}
      >
        <Typography className={classes.titleText}>
          {title}
        </Typography>
        <ExpandMoreIcon
          style={{
            transition: "all 0.3s ease",
            transform: `rotate(${!showChildren ? 0 : "0.5turn"})`
          }}
        />
      </Grid>
      <Collapse in={showChildren}>
        <Grid container className={classes.childrenContainer}>
          {children}
        </Grid>
      </Collapse>
    </Grid>
  );
}

const useStyles = makeStyles({
  sectionContainer: {
    marginBottom: '30px'
  },
  dropdownContainer: {
    width: '100%',
    // @ts-ignore
    borderBottom: `1px solid ${theme.palette.buttonBorder.contrastText}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 10px 10px 10px',
    marginBottom: '20px'
  },
  childrenContainer: {
    textAlign: 'justify'
  },
  titleText: {
    textAlign: 'left',
    width: '80%'
  },
})

export default InfoDropdown;
