import React from "react";
import {Route, Routes} from "react-router-dom";
import localizedStrings from "../../assets/strings";
import Main from "../../screens/main";
import Info from "../../screens/info";

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path={localizedStrings.mainURL}
        element={<Main />}
      />
      <Route
        path={localizedStrings.infoURL}
        element={<Info />}
      />
    </Routes>
  )
}

export default AppRoutes;
