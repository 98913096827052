import React from "react";
import {Grid} from "@mui/material";
import {makeStyles} from "@mui/styles";

type BottomNavigationButtonProps = {
  icon: React.ReactNode,
  onClick: () => void
}

const BottomNavigationButton = (props: BottomNavigationButtonProps) => {
  const classes = useStyles();
  const {icon, onClick} = props;

  return (
    <Grid
      item
      className={classes.btnContainer}
      onClick={onClick}
    >
      {icon}
    </Grid>
  );
}

const useStyles = makeStyles({
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '70px',
  }
})

export default BottomNavigationButton;
