const production = process.env.REACT_APP_PRODUCTION === 'true';
const apiUrlPrefix = production ? "" : "http://localhost:8000"

export const getRandomEventApi = `${apiUrlPrefix}/api/get-random-event`;

export const updateEventApi = (eventId: string, action: string) => {
    return `${apiUrlPrefix}/api/update-event/${eventId}?action=${action}`;
};

export const updateLogsApi = (action: string) => {
    return `${apiUrlPrefix}/api/update-logs?action=${action}`;
}
