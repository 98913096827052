import {createTheme} from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {main: '#DA291C', contrastText: '#FFF'},
    secondary: {main: '#000', contrastText: '#FFF'},
    // @ts-ignore
    bottomNav: {main: '#C8C9C7'},
    buttonBorder: {main: '#DEDEDE', contrastText: '#C8C9C7'}
  },
  text: {
    font: '\'Poppins\', sans-serif',
    fontWeight: {regular: 400, light: 300, bold: 700, semiBold: 600, fiveHundred: 500},
    fontSize: {
      tiny: "10px",
      small: "11px",
      medium1: "12px",
      medium2: "13px",
      medium3: "14px",
      medium4: "15px",
      medium5: "16px",
      paymentAmount: '17px',
      medium6: "18px",
      itemTitle: "20px",
      extraMedium: "21px",
      paymentItemTitle: '22px',
      large: "24px",
      large2: "28px",
      extraLarge: "40px",
      extraExtraLarge: "60px"
    },
  },
  typography: {
    fontFamily: [
      'Poppins, sans-serif'
    ].join(','),
  },
  breakpoints: {
    // @ts-ignore
    mobile: 320,
    mobileLandscape: 480,
    mobileBig: 600,
    tablet: 769,
    sm: 960,
    tabletLandscape: 1024,
    desktop: 1200,
    desktopMedium: 1300,
    desktopLarge: 1500,
    desktopWide: 1920,
  }
});

export default theme;
