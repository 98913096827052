import React, {useEffect} from 'react';
import {withStyles} from "@mui/styles";
import {ThemeProvider} from "@mui/material";
import {BrowserRouter} from "react-router-dom";

import './App.css';
import theme from "./theme";
import Routes from "./components/routes/routes";
import "./utils/helpers/stringFormatting";
import {updateLogsApi} from "./utils/data/constants";
import {makeGetRequest} from "./utils/helpers/apiHelper";

const App = () => {
  useEffect(() => {
    makeGetRequest(updateLogsApi("visit")).then();
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  );
}

const styles = () => ({});

export default withStyles(styles)(App);
