import LocalizedStrings from 'react-localization';

const strings = {
  si: {
    readMore: "Preberi več",
    moreEvents: "Več dogodkov",
    partners: "Partnerji",
    mainURL: "/",
    infoURL: "/info",
    infoTextStart: "Aplikacija je bila ustvarjena v okviru evropskega projekta RESTART_4Danube. Projekt RESTART_4Danube " +
      "se izvaja v okviru podonavskega transnacionalnega programa in ga sofinancirajo 3 skladi Evropske unije - Evropski " +
      "sklad za regionalni razvoj, Instrument za predpristopno pomoč in Evropski sosedski instrument (ESRR, IPA, ENI) - " +
      "preko mehanizma Interreg Danube.",
    aboutProject: "O projektu",
    aboutProjectInfo: "RESTART_4Danube je edinstven projekt, katerega namen je vzpodbuditi regeneracijo urbanih območij v " +
      "srednje velikih mestih s pomočjo aktivacije malih in srednjih podjetij (MSP) ter inovativnih politik. Projekt " +
      "poudarja transnacionalno sodelovanje med MSP, predstavniki raziskovalnega in razvojnega sektorja ter mesti kot " +
      "nujno sestavino za uspešen prenos dobrih praks na področju lokalne/regionalne inovacijske politike ter " +
      "spodbujanje novih poslovnih modelov. RESTART_4Danube se ob strategiji EU za Podonavsko regijo opira tudi na cilje" +
      " iniciative Unija inovacij (Innovation Union, Europe 2020), katere ukrepi so namenjeni krepitvi vloge kulturnih " +
      "in kreativnih industrij kot katalizatorja inovacij in strukturnih sprememb. ",
    aboutProjectIndentsTitle: "Da bi dosegli zastavljene cilje bomo v projektu:",
    aboutProjectIndent1: "●Vzpostavili transnacionalno mrežo za regeneracijo urbanih območij, s pomočjo katere se bodo" +
      " ustvarile nove in vzdrževale že obstoječe povezave med predstavniki kulturnega in kreativnega sektorja, " +
      "kot tudi predstavniki raziskovalnega in razvojnega sektorja ter javne oblasti;",
    aboutProjectIndent2: "●Implementirali 5 pilotnih akcijskih načrtov, ki se osredotočajo na regeneracijo mestnega " +
      "okolja in prostorov z in za akterje kreativnih industrij;",
    aboutProjectIndent3: "●Izboljšali storitve in vplivali na izboljšanje inovacijskih zmožnosti malih in srednje " +
      "velikih podjetij (MSP).",
    infoPartners: "Partnerstvo vključuje 25 projektnih partnerjev iz 12 držav, koordinira pa ga Univerza Politehnica" +
      " iz Bukarešte v Romuniji. Preostale države, ki sodelujejo v projektu, so Nemčija, Slovenija, Hrvaška, Bolgarija," +
      " Avstrija, Madžarska, Bosna in Hercegovina, Moldavija, Ukrajina, Srbija ter Estonija.",
    duration: "Trajanje",
    infoDuration: "07.2020 – 12.2022",
    socials: "Povezave do družbenih omrežij projekta ter spletne strani",
    projectFinanced: "Projekt sofinanciran iz sredstev Evropske unije (ESRR, IPA, ENI).",
    socialsFacebook: "Facebook",
    socialsLinkedin: "LinkedIn business page",
    socialsTwitter: "Twitter",
    socialsInstagram: "Instagram",
    webpage: "Spletna stran",
    madeByStudo: "Izvajalec Studo",
    creators: "Izvajalci",
    optimizedForMobile: "Optimizirano za mobilni telefon",
    participatoryBudgeting: "Participativni proračun Mestne občine Maribor",
    activelyContribute: "Aktivno prispevaj k izboljšanju kakovosti življenja v Mariboru!",
    errorText: "Prišlo je do napake.",
  },
}

const localizedStrings = new LocalizedStrings(strings);

localizedStrings.setLanguage('si');
export default localizedStrings;
