import React from "react";
import {Skeleton} from "@mui/material";
import {makeStyles} from "@mui/styles";

type CustomImageProps = {
  image: string,
  className?: string,
  loading: boolean,
  loadingWidth: string | number,
  loadingHeight: string | number,
}

const CustomImage = (props: CustomImageProps) => {
  const classes = useStyles();
  const {image, className, loading, loadingWidth, loadingHeight} = props;

  return (
    <>
      {loading ? (
        <div className={classes.skeletonContainer}>
          <Skeleton className={classes.skeleton} variant={"rectangular"} width={loadingWidth} height={loadingHeight} />
        </div>
      ) : (
        <img src={image} className={className} alt={""}/>
      )}
    </>
  );
}

const useStyles = makeStyles({
  skeletonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  skeleton: {
    borderRadius: '8px',
  }
})

export default CustomImage;
