import React, {useEffect, useState} from "react";
import {Grid, Skeleton, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import BottomNavigationBar from "../components/bottomNavigation/bottomNavigationBar";
import theme from "../theme";
import moment from "../utils/helpers/moment";
import ReadMoreButton from "../components/buttons/readMoreButton";
import ButtonSection from "../components/buttons/buttonSection";
import localizedStrings from "../assets/strings";
import {moreEventsData, partnersData} from "../utils/data/buttonsData";
import {makeGetRequest} from "../utils/helpers/apiHelper";
import {getRandomEventApi, updateEventApi, updateLogsApi} from "../utils/data/constants";
import Footer from "../components/footer/footer";
// @ts-ignore
import Cookies from 'js-cookie';
import CustomTypography from "../components/elements/customTypography";
import CustomImage from "../components/elements/customImage";

type SelectedEvent = {
  id: string,
  title: string,
  start_date: string,
  end_date?: string,
  image: string,
  url: string,
  description: string
}

const Main = () => {
  const classes = useStyles();

  // @ts-ignore
  const [selectedEvent, setSelectedEvent] = useState<SelectedEvent>(null);
  const [likeActive, setLikeActive] = useState(false);
  const [language, setLanguage] = useState(Cookies.get('googtrans'));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchData = async (url: string) => {
    return await makeGetRequest(url);
  }

  const updateEvent = async (action: string) => {
    const response = await fetchData(updateEventApi(selectedEvent.id, action));
    if (!!response?.id) {
      setLikeActive(!likeActive);
    }
    return !!response?.id
  }

  const googleTranslateElementInit = () => {
    // @ts-ignore
    new window.google.translate.TranslateElement({
        pageLanguage: 'sl',
        includedLanguages: 'en,sl',
        autoDisplay: false,
      },
      'google_translate_element');
  }

  useEffect(() => {
    // @ts-ignore
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, [])

  useEffect(() => {
    if (!selectedEvent) {
      // React 18.x has some issues with useEffect function being async, so this way we can avoid it
      getRandomEvent().then()
    }
  }, [selectedEvent]);

  const getRandomEvent = async () => {
    setLoading(true);
    const response = await fetchData(getRandomEventApi);

    if (response[0]) {
      setSelectedEvent(response[0])
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  }

  const switchTranslationTo = (language: string) => {
    // @ts-ignore
    const googleLanguageSelect: HTMLSelectElement = document.querySelector(".goog-te-combo")!!;

    Array.from(googleLanguageSelect.options).forEach((option: HTMLOptionElement) => {
      if (option.value === language) {
        option.selected = true;

        // If not dispatching change event new language doesn't apply until page reload
        // @ts-ignore
        googleLanguageSelect.dispatchEvent(new Event('change'));
      }
    });
  }

  return (
    <BottomNavigationBar
      onLikeClick={async () => {
        await updateEvent(!likeActive ? "like" : "dislike");
      }}
      likeActive={likeActive}
      translationActive={language === '/sl/en'}
      onTranslateClick={() => {
        if (language === '/sl/en') {
          switchTranslationTo('sl');
          setLanguage('/sl/sl');
        } else {
          // Needed to happen twice, because the first time it resets and doesn't translate to english for some unknown
          // reason
          switchTranslationTo('en');
          switchTranslationTo('en');
          setLanguage('/sl/en');
        }
      }}
      onRefreshClick={async () => {
        if (!loading) {
          await getRandomEvent();
          await fetchData(updateLogsApi("refresh"));
          setLikeActive(false);
        }
      }}
    >
      <div id={"google_translate_element"}/>
        <Grid container className={classes.eventContainer}>
          {(error && !loading) ? (
              <Grid container className={classes.errorTextContainer}>
                <Typography>{localizedStrings.errorText}</Typography>
              </Grid>
          ) : (
            <Grid container className={classes.innerEventContainer}>
              <Grid item className={classes.fullWidth}>
                <Grid item>
                  <CustomImage
                    image={selectedEvent?.image}
                    className={classes.eventImage}
                    loading={loading}
                    loadingWidth={'100%'}
                    loadingHeight={200}
                  />
                </Grid>
                <Grid item className={classes.titleContainer}>
                  <CustomTypography
                    className={classes.titleText}
                    loading={loading}
                    loadingWidth={'100%'}
                    loadingHeight={35}
                    type={"text"}
                  >
                    {selectedEvent?.title}
                  </CustomTypography>
                  <CustomTypography
                    loading={loading}
                    loadingWidth={'70%'}
                    loadingHeight={25}
                    type={"text"}
                  >
                    {
                      selectedEvent?.end_date
                        ? `${moment(selectedEvent?.start_date).formatDate()} 
                          - ${moment(selectedEvent?.end_date).formatDate()}`
                        : moment(selectedEvent?.start_date).formatDate()
                    }
                  </CustomTypography>
                </Grid>
                <Grid item className={classes.titleContainer}>
                  <CustomTypography
                    sx={{
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 3,
                    }}
                    loading={loading}
                    loadingWidth={'100%'}
                    loadingHeight={90}
                    type={"rectangular"}
                  >
                    {selectedEvent?.description}
                  </CustomTypography>
                </Grid>
              </Grid>
              <Grid item className={classes.buttonContainer}>
                <ReadMoreButton
                  title={selectedEvent?.url.getBaseUrl()}
                  onClick={() => window.location.href = selectedEvent?.url}
                  loading={loading}
                />
              </Grid>
            </Grid>
          )}
          <ButtonSection title={localizedStrings.moreEvents} buttonList={moreEventsData}/>
          <ButtonSection title={localizedStrings.partners} buttonList={partnersData}/>
        </Grid>
        <Footer/>
    </BottomNavigationBar>
  );
}

const useStyles = makeStyles({
  eventContainer: {
    padding: '30px',
    paddingBottom: '50px'
  },
  innerEventContainer: {
    minHeight: '78vh',
    display: 'flex',
    // @ts-ignore
    flexDirection: 'column !important',
    justifyContent: 'space-between',
  },
  eventImage: {
    width: '100%',
    height: '100%',
  },
  titleContainer: {
    marginTop: '30px !important',
    width: '100%',
    textAlign: 'center',
  },
  buttonContainer: {
    marginTop: '30px !important',
    marginBottom: '30px !important',
    width: '100%',
    display: 'flex',
    // @ts-ignore
    flexDirection: 'column !important',
    alignItems: 'center !important',
    justifyContent: 'flex-end !important'
  },
  titleText: {
    // @ts-ignore
    fontWeight: `${theme.text.fontWeight.bold} !important`,
    // @ts-ignore
    fontSize: `${theme.text.fontSize.medium6} !important`,
  },
  fullWidth: {
    width: '100%'
  },
  errorTextContainer: {
    minHeight: '78vh',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

export default Main;
