
export const makeGetRequest = async (url: string) => {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    }
  });

  if (!response.ok) {
    return false;
  }

  return await response.json();
}
