import React from "react";
import {Grid} from "@mui/material";
import {makeStyles} from "@mui/styles";
import TranslateIcon from '@mui/icons-material/Translate';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

import BottomNavigationButton from "./bottomNavigationButton";
import BottomNavigationButtonClass from "../../utils/classes/BottomNavigationButton";
import theme from "../../theme";
import localizedStrings from "../../assets/strings";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "../../utils/helpers/windowDimensions";

type BottomNavigationBarProps = {
  onLikeClick: () => void,
  likeActive: boolean,
  onTranslateClick: () => void,
  translationActive: boolean,
  onRefreshClick: () => void,
  children: any,
}

const BottomNavigationBar = (props: BottomNavigationBarProps) => {
  const classes = useStyles();
  const {onLikeClick, likeActive, onTranslateClick, translationActive, onRefreshClick, children} = props;

  const navigate = useNavigate();
  const {width} = useWindowDimensions();

  const buttons = [
    new BottomNavigationButtonClass(
      <TranslateIcon
        sx={{ fontSize: translationActive ? 29 : 27 }}
        color={translationActive ? "primary" : "secondary"}
      />,
      () => {
        onTranslateClick();
      }
    ),
    new BottomNavigationButtonClass(
      <InfoOutlinedIcon sx={{ fontSize: 27 }} />,
      () => navigate(localizedStrings.infoURL)
    ),
    new BottomNavigationButtonClass(
      !likeActive ? (
        <FavoriteBorderOutlinedIcon
          sx={{ fontSize: 27 }}
          color={"secondary"}
        />
      ) : (
        <FavoriteIcon
          className={classes.likeAnimation}
          sx={{ fontSize: 32 }}
          color={"primary"}
        />
      ),
      () => onLikeClick()
    ),
    new BottomNavigationButtonClass(
      <RefreshOutlinedIcon sx={{ fontSize: 27 }} />,
      () => {
        onRefreshClick();
      }
    ),
  ]

  return (
    <Grid className={classes.outerDiv}>
      <Grid className={classes.mainDiv}>
        {children}
        <Grid className={width > 540 ? `${classes.bottomNav} ${classes.bottomNavCenter}` : classes.bottomNav}>
          {buttons.map((button, index) => (
            <React.Fragment key={index}>
              <BottomNavigationButton
                icon={button.icon}
                onClick={button.onClick}
              />
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  bottomNav: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    // @ts-ignore
    backgroundColor: theme.palette.buttonBorder.contrastText,
    maxWidth: '540px !important'
  },
  bottomNavCenter: {
    left: '50%',
    marginLeft: '-270px'
  },
  likeAnimation: {
    animation: "like-animation 0.2s ease"
  },
  mainDiv: {
    maxWidth: '540px !important',
    position: 'relative',
    backgroundColor: 'white'
  },
  outerDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#E6E6E6'
  }
})

export default BottomNavigationBar;
