import moment from "moment";
import "moment-timezone";

declare module "moment" {
  interface Moment {
    formatDate(): string;
  }
}

(moment as any).fn.formatDate = function (): string {
  const date = this.tz("Etc/Universal");
  let format = 'DD.MM.yyyy ob HH:mm';
  if (date.hour() === 0 && date.minute() === 0) {
    format = 'DD.MM.yyyy'
  }

  return this.tz("Etc/Universal").format(format);
};

export default moment;
