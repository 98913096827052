import React from "react";
import {Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

type InfoSocialsButtonProps = {
  title: string,
  onClick: () => void
}

const InfoSocialsButton = (props: InfoSocialsButtonProps) => {
  const classes = useStyles();
  const {title, onClick} = props;

  return (
    <Grid item>
      <Typography className={classes.inline}>●</Typography>
      <Typography
        className={classes.linkText}
        onClick={() => onClick()}
      >
        {title}
      </Typography>
    </Grid>
  );
}

const useStyles = makeStyles({
  linkText: {
    display: 'inline-block',
    textDecoration: 'underline',
    marginLeft: '20px !important',
  },
  inline: {
    display: 'inline'
  },
})

export default InfoSocialsButton;
